import React from 'react';
import '../HomePage.css';

const photos = [
  {id:48,url:'https://i.imgur.com/leyVsT7.jpg',type:'image'},
  {id:49,url:'https://i.imgur.com/Bza8Nbj.jpg',type:'image'},
  {id:50,url:'https://i.imgur.com/vcHZTgE.jpg',type:'image'},
  {id:51,url:'https://i.imgur.com/UevAyXB.jpg',type:'image'},
  {id:24,url:'https://i.imgur.com/WqewZyZ.jpg', type:'image'},
  {id:25,url:'https://i.imgur.com/bupFQYv.jpg', type:'image'},
  {id:26,url:'https://i.imgur.com/nIWKfO7.jpg', type:'image'},
  {id:27,url:'https://i.imgur.com/RexCQVt.jpg', type:'image'},
  {id:28,url:'https://i.imgur.com/J7IxBjB.jpg', type:'image'},
  {id:29,url:'https://i.imgur.com/CluaEIV.mp4', type:'video'},
  {id:30,url:'https://i.imgur.com/0c3zrNP.jpg', type:'image'},
  {id:31,url:'https://i.imgur.com/nKyo8iG.jpg', type:'image'},
  {id:32,url:'https://i.imgur.com/qGvsMaw.mp4', type:'video'},
  {id:33,url:'https://i.imgur.com/77wrpja.mp4', type:'video'}, 
  { id: 17, url: 'https://i.imgur.com/Sndz597.mp4', type: 'video'},
  { id: 13, url: 'https://i.imgur.com/0g9AGgP.jpg', type: 'image'},
  { id: 14, url: 'https://i.imgur.com/EAsqduk.jpg', type: 'image'},
  { id: 15, url: 'https://i.imgur.com/KdDP03E.jpg', type: 'image'},
  { id: 18, url: 'https://i.imgur.com/zqUMwKq.jpg', type: 'image'},
  { id: 22, url: 'https://i.imgur.com/2oSL7lA.jpg', type: 'image'},
  { id: 23, url: 'https://i.imgur.com/Tl53tsG.jpg', type: 'image'},
  { id: 19, url: 'https://i.imgur.com/Z2ayhds.jpg', type: 'image'},
  { id: 20, url: 'https://i.imgur.com/tYOxAtM.jpg', type: 'image'},
  { id: 21, url: 'https://i.imgur.com/FFOvDua.jpg', type: 'image'},
  { id: 16, url: 'https://i.imgur.com/VPK4yWt.jpg', type: 'image'},
  { id: 1, url: 'https://i.imgur.com/MGJUsuS.jpg', type: 'image'},
  { id: 2, url: 'https://i.imgur.com/JkItsKk.jpg', type: 'image'},
  { id: 3, url: 'https://i.imgur.com/jUw1yq4.jpg', type: 'image'},
  { id: 4, url: 'https://i.imgur.com/b5t3RFf.jpg', type: 'image'},
  { id: 5, url: 'https://i.imgur.com/f3H3MHj.jpg', type: 'image'},
  { id: 6, url: 'https://i.imgur.com/GEirld5.jpg', type: 'image'},
  { id: 7, url: 'https://i.imgur.com/S1witU2.jpg', type: 'image'},
  { id: 8, url: 'https://i.imgur.com/g3I7yTn.jpg', type: 'image'},
  { id: 9, url: 'https://i.imgur.com/8Vo1Eco.jpg', type: 'image'},
  { id: 10, url: 'https://i.imgur.com/6FgAPu5.jpg', type: 'image'},
  { id: 11, url: 'https://i.imgur.com/0hoEuti.jpg', type: 'image'},
  { id: 12, url: 'https://i.imgur.com/ch8Hr7e.jpg', type: 'image'},
  {id:34,url:'https://i.imgur.com/McZR8Ve.mp4',type:'video'},
  {id:35,url:'https://i.imgur.com/H0zfsMC.jpg',type:'image'},
  {id:36,url:'https://i.imgur.com/aR2qgjV.jpg',type:'image'},
  {id:37,url:'https://i.imgur.com/vavEbTX.jpg',type:'image'},
  {id:38,url:'https://i.imgur.com/DiievcS.jpg',type:'image'},
  {id:39,url:'https://i.imgur.com/O6mVBM8.jpg',type:'image'},
  {id:40,url:'https://i.imgur.com/9In3Mh2.jpg',type:'image'},
  {id:41,url:'https://i.imgur.com/uNTV5ES.jpg',type:'image'},
  {id:42,url:'https://i.imgur.com/4m6hky3.jpg',type:'image'},
  {id:43,url:'https://i.imgur.com/pgeWEkE.jpg',type:'image'},
  {id:44,url:'https://i.imgur.com/dxwjZCk.jpg',type:'image'},
  {id:45,url:'https://i.imgur.com/Su56ovV.jpg',type:'image'},
  {id:46,url:'https://i.imgur.com/ViSnfAD.jpg',type:'image'},
  {id:47,url:'https://i.imgur.com/McZR8Ve.mp4',type:'video'},
  
];

const HomePage = () => {
  return (
    <>
      <h1 className='center'>Aerial Marketing Media Services</h1>
      <div className="home-container">
        {photos.map(photo => (
          <a href={photo.url} key={photo.id} target="_blank" rel="noopener noreferrer">
            <div className="photo-card">
              {photo.type === 'image' ? (
                <img src={photo.url} alt={`Image ${photo.id}`} />
              ) : (
                <video className='photo-card' controls>
                  <source src={photo.url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          </a>
        ))}
      </div>
    </>
  );
};

export default HomePage;
